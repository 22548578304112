<template>
<div>
    <Header></Header>
         <!--顶部banner开始-->
         <div class="aboutus-banner left"> <img src="~images/banner/jiuyezhidao.jpg" width="100%" /> </div>

        <!--内容开始--->
        <div class="obtain-box left width-bai">
            <div class="obtain-content center clearfix">
                <!--面包屑开始-->
                <Breadcrumb>
                <a href="/#/index" title="首页"> <BreadcrumbItem>首页</BreadcrumbItem> </a>
                <BreadcrumbItem>就业指导</BreadcrumbItem>
                </Breadcrumb>

                <div style="height:20px;"></div>

                <!--学科列表开始-->
                <div class="obtain-subject-left left clearfix">
                    <a :href="'/#/obtain_employment_detail?obtain='+item.id" :title="item.title" v-for="item in list" :key="item.id">
                    <div class="obtain-subject-li left clearfix">
                        <h1>{{item.title}}</h1>
                        <p class="obtain-subject-text">{{item.introduce}}</p>
                        <p class="obtain-subject-button"><button>查看详情</button></p>
                    </div>
                    </a>
                </div>
            </div>
        </div>

        <div class="left clearfix width-bai"> <Footer></Footer> </div>
        <contact-call></contact-call>
</div>
</template>
<script>
import ContactCall from '../index/ContactCall.vue'
import Header from '../layout/Header.vue'
import Footer from '../layout/Footer.vue'
export default { 
   name:"",
   data() {
      return {
          list:''
      }
   },
   components:{
    ContactCall,
    Header,
    Footer
  },

  mounted() {
     this.$axios.post(window.configs.svcProt + "://" + window.configs.svcIpPort + '/get_obtain_list.html?token='+this.$store.state.token).then(res=>{
                this.list=res.data.result;
            });
  },
}
</script>

<style scoped>
.obtain-subject-text{height:120px;}
.obtain-subject-button{line-height:40px;text-align:right;}
.obtain-subject-button button{padding:0px 10px;background:rgb(242,151,0);color:#fff;border:none;border-radius:5px;}
.obtain-subject-li h1{font-size:16px;font-weight:bold;line-height:50px;padding-left:20px;border-radius:5px;}
.obtain-subject-li p{padding:10px;line-height:30px;}
.obtain-subject-li:hover{box-shadow: 0px 2px 5px rgb(242,151,0);}
.obtain-subject-li{width:400px;height:220px;box-shadow: 0 0 10px 1px rgb(0 0 0 / 10%);margin:20px;margin-left:49px;cursor:pointer;border-radius:5px;}
.obtain-subject-left{width:1440px;margin-top:30px;min-height:800px;}
.obtain-content{width:1440px;min-height:800px;margin-top:10px;}
.obtain-box{min-height:800px;}

</style>
